<template>
  <div id="home">

    <div class="container">
      <div class="row introSec vh-100" id="introSec">
        <div class="col-xl-6 col-md-12 slideCaption align-self-center">
          <div class="container text-focus-in delay-15">

            <div class="row">
              <h1 class="puff-in-center"
                  v-html="project.title"></h1>
              <p v-html="project.description"></p>
            </div>

            <div class="row">
              <router-link :to="{name: 'Masterplan'}" class="cta">
                <span class="icn"><img width="40" height="40" src="@/assets/images/icons/MV.png" alt="iCity New Cairo"/></span>
                <span class="txt">{{ project.master_button }}</span>
              </router-link>

              <template v-for="cta in project.gallery" :key="cta.id">
                <button class="cta" data-toggle="modal" :data-target="'#modal'+cta.id" @click="updateVideo()">
                  <span class="icn"><img width="40" height="40" src="@/assets/images/icons/Play.svg" alt="How to get there"/></span>
                  <span class="txt"> {{ cta.call_to_action }}</span>
                </button>


                <teleport to="body">
                  <modal :data="cta"></modal>
                </teleport>
              </template>
              <button v-if="project['button_style'] === 'white'" @click="openLink(project.location)"  class="cta">
                <span class="icn"><img width="32" height="40" src="@/assets/images/icons/location.svg" alt="Get directions"/></span>
                <span class="txt">
                    Get Directions
                  </span>
              </button>

            </div>

            <a v-if="project['button_style'] === 'transparent'" :href="project.location" rel="noopener" target="_blank" class="directions row">
              <span class="icn"><img width="32" height="40" src="@/assets/images/icons/location.svg" alt="Get directions"/></span>
              <span class="txt">
                Get Directions
              </span>
            </a>
        </div>
        </div>

        <div class="col-xl-6 col-md-12 imageContainer d-flex justify-content-center align-items-center">
              <img
                src="@/assets/images/homeVector/1.webp"
                alt="Mountain View Villa"
                class="puff-in-center delay-15"
                rel="preload"
              />
              <img
                :src=" getImage(project['small_pic'],'general') "
                alt="Mountain View Villa"
                class="puff-in-center delay-1"
                rel="preload"
              />
              <img
                :src=" getImage(project['big_pic'],'general') "
                alt="Mountain View Villa"
                class="puff-in-center delay-05"
                rel="preload"
              />
              <img
                src="@/assets/images/homeVector/4.webp"
                alt="Mountain View Villa"
                class="puff-in-center delay-2"
                rel="preload"
              />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex';
export default {
  name: "Home",
  components: {
  },
  computed:{
    ...mapGetters(['getImage']),
    ...mapState(['project'])
  },
  data(){
    return{
    }
  },
  beforeCreate() {
    this.$store.dispatch('loadNotification',{domain:'hyde'});
    this.$store.dispatch('loadProject',{domain:'hyde'});
    this.$store.dispatch('loadPlans',{domain:'hyde'});
  },
  methods: {
    updateVideo(){
      var vidDefer = document.getElementsByTagName('iframe');
        for (var i=0; i<vidDefer.length; i++) {
          if(vidDefer[i].getAttribute('data-src')) {
          vidDefer[i].setAttribute('src',vidDefer[i].getAttribute('data-src'));
          }
        }
    },
    openLink(val){
      window.open(val);
    },
  },
  mounted(){
    console.log('hyde');
  }
};

</script>

<style scoped>
  .imageContainer img {
    max-height:110%;
    position: absolute;
  }
  .directions{
    padding:15px;
    position: relative;
    top: 25px;
    transition:all 1s ease;
    left:0;
  }
  .directions:hover{
    left:10px;
  }
  .directions img{max-height:32px;transition:all 1s ease;}
  .directions:hover img{transform: scale(1.1);}

  .directions .txt{
    font-size:1.2rem;
    font-weight: 600;
  }
  .slideCaption p{font-size:1.7rem;}
</style>
